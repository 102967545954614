import React from "react"
import { graphql, StaticQuery } from 'gatsby';
import styled from "styled-components"
import Layout from "../components/Layout"
import theme from "../general/theme"
import Box from "../elements/Box"
import {
  Trafalgar,
  BodyCopy
} from '../typography';
const { white, gray7, gray8 } = theme.colours;

export default props => (
  <StaticQuery
  query={graphql`
    query Index {
      allSanityHomePage {
        edges {
          node {
            title
            description
            youtubeEmbedCode
            team_image {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `}
  render={data => <Index data={data} {...props} />}
  />
)

const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const Section = styled.section`
    margin-bottom: 8rem;
`;

const TextBlock = styled.div`
    max-width: 850px;
    
    .description {
      text-align: justify; 
    }
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8rem;
  `;
  
  const VideoBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8rem;
`;


const Index = ({data}) => (
  <Layout>
    <Content>
      <>
        <Section>
          <Box px="4rem" py="8rem" background={white} borderRadius="8px">
            <TextBlock>
              <>
                <Trafalgar color={gray8} mb="4rem">{data.allSanityHomePage.edges[0].node.title}</Trafalgar>
                <BodyCopy className="description" color={gray7} mb="8rem">{data.allSanityHomePage.edges[0].node.description}</BodyCopy>
                <VideoBox>
                  <div dangerouslySetInnerHTML={{__html: data.allSanityHomePage.edges[0].node.youtubeEmbedCode}} />
                </VideoBox>
                <ImageBox>
                  <img src={`${data.allSanityHomePage.edges[0].node.team_image.asset.url}?w=500&q=100`} width={500} alt="Agewell Group"/>
                </ImageBox>
              </>
            </TextBlock>
          </Box>
        </Section>
      </>
    </Content>
  </Layout>
);